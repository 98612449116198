import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ Launches Industry’s First" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Launches Industry’s First 5G Chipset-as-a-Service for 5G Wireless Infrastructure Market</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>EdgeQ’s Chipset-as-a-Service shifts the industry to a service oriented, pay-as-you-go model, allowing customers to</i></p>

              <p className="text-left"><i>configure 5G and AI services through EdgeQ’s unique software defined 5G base station-on-a-chip platform</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
                    <p><b>SANTA CLARA, CA</b> – June 15, 2021 – <Link to="/">EdgeQ Inc</Link>, a 5G systems-on-a-chip company, today introduces industry’s first 5G chipset-as-a-service model, offering customers a future proof platform that can scale 5G and AI features as a function of subscription payments. This new service model is the very first in the chip industry to scale price, performance, and features as a function of need and use. With 5G expected to address a rich cross section of end points, ranging from consumer smartphones to industrial 4.0 automation to V2X communications to radio and distributed unit base stations, the traditional silicon approach of a highly leveraged, single priced design for all markets is no longer viable. Customers look to a subscription-based model with different service tiering to mirror need and use. A service-oriented model provides customers the freedom to scale from nominal to advanced 5G features such as ultra- reliable low latency communications, geo-location services, massive MIMO, fine-grained network slicing, as well as extending compatibility to other legacy wireless protocols. By uniquely coupling EdgeQ’s software-driven base station-on-a-chip technology with a chipset-as-a-service model, customers can now focus on a virtualized 5G deployment model where feature attributes can be turned on and off via software. EdgeQ’s unique combination of an elastic chip architecture and a softwarized RAN (radio access network) stack allows customers to only pay for features that map to their real needs.</p>

              <p>By offering chipset-as-a-service, EdgeQ elevates 5G Open Radio Access network (O-RAN) to an even more configurable, elastic, open wireless infrastructure. Enterprise network, telco, and cloud providers can use EdgeQ to virtualize network resources, allowing elastic scaling of 5G services on demand. Customers can pace their needs and demands via tiered subscription packages, thereby minimizing massive upfront capex of fully burdened hardware. Network providers can instantaneously provision finely tuned services (such as massive MIMO or Ultra-Reliable Low Latency Communications) and incorporate a matching pricing model based on use and value. EdgeQ’s ORAN compliant 5G platform allows dynamic reconfiguration and future scale without the need to rip and replace field-deployed systems. And customers can now access a more intelligent, elastic, services-driven physical layer.</p>

              <b>Pay-as-you-go model delivers cost-efficiency and full customizability</b>
              <br />
              <br />
              <p>EdgeQ uniquely converges 5G and AI in a richly formatted but software-defined manner. By leveraging a paid subscription model, customers can select specific packages for 5G services that best reflect the level of user support, coverage, bandwidth, and network services. As a result, organizations can deploy EdgeQ inexpensively with basic 5G connectivity, and then upgrade to more advanced services as their business needs evolve. This approach allows customers to lower upfront CAPEX costs and derisk investment in features and functionalities that may never get used.</p>

              <p>"EdgeQ is taking an innovative approach to the business model for semiconductors in wireless infrastructure. As in other markets, the opportunity is to shift from up-front cost to a ‘pay as you go’ model, and EdgeQ proposes to use this business model right down to the  chip  level”, said  Joe  Madden, Chief Analyst and Founder of Mobile Experts Inc. “By creating a common architectural platform whose feature set scales through software, EdgeQ proposes to shift the value point from fixed hardware design to elastic software differentiation. This could potentially save money over the life cycle of a radio by enabling quick and easy software upgrades for new features, moving the hardware-based radio market in the same direction as cloud-native virtual networks.”</p>

              <p>EdgeQ’s new business model gives organizations the flexibility and extensibility to manage and nurture their networks fluidly. The control and management become purely cloud-based. The price- performance becomes tightly correlated to real needs. And customers can now dynamically scale their 5G networks in an environment known for long life cycles and unpredictable unknowns.</p>

              <p>“Our vision at EdgeQ has always been about implementing 5G in a format that is accessible, consumable, and intuitive for our customers. EdgeQ is not only the first company to converge both 5G and AI on a single chip for wireless infrastructure, but we are also able to make those capabilities available in a SaaS model. This fundamentally reduces the initial capex investment required for 5G, thereby removing both technical and economic barriers of 5G adaptation at greenfield enterprises,” said Vinay Ravuri, CEO and Founder, EdgeQ. “This pay-as-you-go model ensures that the evolving demands  of the market can leverage the full fluidity and elasticity of EdgeQ’s 5G-as-a-Service product.”</p>

              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering a converged connectivity and AI that is fully software-customizable and programmable. The company is backed by leading investors, including Threshold Partners, Fusion Fund and AME Cloud Ventures, among others. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit;
